.App {
  text-align: center;
}
.wrapper{
  padding: 0px 8px;
  min-height:93vh;
}
.teaser-card .wrapper {
  padding: 0px 8px;
  height: 35vh !important;
}
html,
body {
  height: 100%;
  margin: 0;
}
/*************** FONT STYLES ************/
h2{
  font-size: clamp(.8rem,3vw,1.5rem);
  margin: 4px;
font-family: 'Outfit', sans-serif;
}
h3{
  margin:0;
}
th{
  font-family: 'Outfit', sans-serif;
font-weight: 700;
}
.nav h1 {
  font-family: 'Montserrat Alternates', sans-serif;
    margin: 0;
      /* Remove default margin */
      font-size: clamp(1rem, 3vw, 2rem);
      /* Larger font size for the title */
      font-weight: 700;
}
/* NAV STYLES */
/* Styles for the navigation bar */

.nav {
  flex-direction:column;
  gap:15px;
  min-height: 3.5vh;
  background-color: #2f3132;
  /* Dark background for the nav */
  padding: 10px 20px;
  /* Some padding around the nav */
  display: flex;
  /* Use flexbox for alignment */
  align-items: center;
  /* Center items vertically */
  justify-content: space-between;
  /* Space out items */
  color: white;
  /* Light text color */
}

/* Dropdown styles remain unchanged */
.nav-item.dropdown {
  position: relative;
  display: inline-block;
}

.nav-item .nav-link {
  cursor: pointer;
  padding: 10px 15px;
  white-space: nowrap;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 12px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: #82b5aa;
}

.dropdown:hover .dropdown-content {
  display: block;
  margin-top: 1px;
  z-index: 99;
}

/* Additional styles for active/hover state in mobile nav mode */
.nav-links.active .dropdown-content {
  display: none;
}

.hamburger-menu.open+.nav-links .dropdown-content {
  display: none;
}

/* Hide hamburger menu if not needed */
.hamburger-menu {
  display: none;
}

/* Navigation links container spanning full width */
.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  /* Space between nav items */
  padding: 0 20px;
  /* Side padding */
}

/* Gradient Animation */
@keyframes gradientAnimation {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

/* Uniform nav link styling */
.nav-link {
        text-decoration: none;
  flex: 1;
  text-align: center;
  background: linear-gradient(#2f3132, #2f3132) padding-box,
    linear-gradient(to right, rgb(107, 114, 255), rgb(161, 194, 255)) border-box;
  border: 2px solid transparent;
  border-radius: 25px;
  /* Modern pill-like shape */
  animation: gradientAnimation 3s ease infinite;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  /* Remove individual margins for uniform sizing */
  transition: transform 0.3s, background-color 0.2s;
  cursor: pointer;
}
 
/* Specific adjustments for receiving if needed */
.nav-link.receiving {
  background: linear-gradient(-45deg, rgb(209, 255, 226), rgb(210, 255, 248), rgb(123, 247, 251));
  animation: gradientAnimation 3s ease infinite;
  transition: transform 0.3s, background-color 0.2s;
}

/* Hover effects */
.nav-link:hover {
  transform: scale(1.03);
}

/* Active state styling */
.nav-link.active,
.nav-link:active {
  background: linear-gradient(-45deg, rgb(44, 164, 229), rgb(212, 210, 255), rgb(0, 166, 255));
  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
  color: rgb(56, 56, 56);
  /* Using flex: 1 ensures uniform sizing without setting flex-basis explicitly */
}

/* Logout button styling to match nav links */
.logout-button {
  flex: .25;
  padding: 10px 15px;
  /* Matching padding with .nav-link */
  background: linear-gradient(-45deg, #ffbde5, #1593a9, #ffb1e0);
  background-size: 200% 200%;
  border: none;
  border-radius: 50px!important;
  animation: gradientAnimation 3s ease infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, background-color 0.2s;
  cursor: pointer;
}

/* Logout icon styling */
.logoutIcon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%) drop-shadow(0px 0px 1px rgb(255, 255, 255));
}

/* Hide logout text by default */
.logout-text {
  display: none;
}

/* **********************Login Page Styles ***********************/
.google-sign-in-button {
  background-color: rgba(255, 255, 255, 0.79); /* Google's brand color */
  color: #db4437;
  width: 100%;
  margin:0px 2px;
  margin-top:10px;
  padding: 5px 15px;
  border: 3px solid #db4437;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.google-sign-in-button:hover {
background-color: rgba(229, 115, 115, 0.79);
color:white; /* Lighter shade for hover effect */
}

.login-container {
  font-family: Arial, sans-serif;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.login-form-container {
  background-image: url('./assets/loginGraphicBg.webp');
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  height: 25vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-size: cover;
  /* Ensure the image covers the container */
  background-position: center;
  /* Center the background image */
}

.login-form-title {
  font-size: clamp(.8rem, 4vw, 1.9rem);
  text-shadow: 1px 1px 5px #000000;
  box-shadow: 1px 1px 2px #000000; ;
  text-align: center;
  color: #f0f0f0;
  margin-bottom: 24px;
}

.login-input-group {
  margin-bottom: 20px;
  position: relative;

}

.login-input-group label {
  display: block;
  color: #333;
  margin-bottom: 5px;
  position: relative;

}

.login-input {
  width: 100%;
  padding: 10px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button-container {
  text-align: center;
}
.placeholder-span {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  pointer-events: none; /* Allow clicks to pass through */
  /* Style it to look like a placeholder */
  color: #999;
  font-size: 16px; /* Match your input font size */
}

.login-button {
  background-color: #e50914;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}
.login-input:focus + .placeholder-span,
.placeholder-span.active {
  top: 0;
  transform: translateY(-100%);
  font-size: 12px;
  color: #666;
}

/******************** WIDGETs & TABLES STYLES **************/
.Header{
  position: sticky;
  top: 0; 
  min-width: 100%;
  background: white; 
  z-index: 1; 

}
thead{
  position: sticky;
    top: 0;
}
th{
  border: 1px solid black;
  background-color: rgb(238, 238, 238);
  border-bottom: 5px solid black; 
  position: sticky;
  top: 27px;
  z-index: 1;
}
.combined-widgets-container {
  flex-wrap: wrap;
  gap: 20px;
}

.widget-container {
  flex: 1; /* Adjust as needed for your layout */
  max-width: 100%;
  overflow-x: auto;
  min-height: 20vh;
  max-height: 46vh;
  margin-bottom: 5px;

}
.gradient-divider {
  position: relative;
}

.gradient-divider::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  background: linear-gradient(-45deg, #ff6ec4, #ffffff);
  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}
td, th {
  border: 1px solid black;
  min-width: 85px; 
  min-height: 15%; 
  padding: 3px;
  white-space: normal;
  font-size: clamp(.4rem,1.9vw,1rem );

}
table {
  border: 1px solid black;
  width: 100%;
  overflow-x: auto;
  table-layout: auto; /* Fixed table layout */
  border-collapse: collapse;
}

.loadingGif{
  width: clamp(150px, 50vw, 480px);
}
/* SUMMARY PAGE */
.chart-container {
  width: 40%;
}
.summary-1{
  height: 25vh;
}
.summary-widget{
  padding: 25px 0px;
}

tfoot{
  position:sticky;
  bottom: 0;
  background-color: white;
}

/* FOOTER STYLES */
.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2vh;
  background-color: rgb(54, 52, 52);
  color:rgb(247, 226, 226);
  margin:auto 0 ;
  bottom:0;
  font-size: .5rem;
}

.doughnut-center-text{
  font-weight: 550;
  font-size: clamp(10px,3vw,15px);

}

/* ANNOUNEMENT STYLES */

.announcements-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.announcement-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-width: 300px;
  cursor: pointer;
  padding: 20px;
  justify-content: space-between;
  transition: transform 0.2s;
}

.announcement-card:hover {
  transform: translateY(-5px);
  background-color: #fbfbfb;
}

.announcement-title {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.announcement-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center children */
  text-align: center;
  /* Center text */
  color: #1c1c1c;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.announcement-text img {
  max-width: 100px;
  max-height: 100px;
  margin: 10px 0;
  /* Adjust as needed */
}

.announcement-text a {
  word-break: break-all;
  /* Ensure long URLs don't cause overflow */
  /* Optional: You might need to adjust padding or margin to ensure it doesn't overflow */
}

.announcement-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.announcement-poster {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
}
.teaser-container {
  margin-top: 20px;
}


.teaser-container {
    margin-top: 20px;
}

/* .teaser-card {
    max-height: 40vh;
    cursor: pointer;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.announcements-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.announcement {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.announcement h3 {
  margin: 0;
}

.announcement p {
  margin: 5px 0 0 0;
}
.announcement-footer small {
  color: #999;
}
.announcement-modal-close-button{
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-top: 5px;
  font-size: 1.3rem;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  max-width: 60%;
  width: 90%;
      position: relative;
        max-height: 80vh;
        /* Example max height */
        overflow-y: auto;
        /* Enables vertical scrolling */
        padding: 20px;
  /* Responsive width */
}
.modal-text-content{
  font-size: clamp(.6rem,2vw,1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.type-selector {
  appearance: none;
  /* Remove default browser styling */
  background-color: #f2f2f2;
  /* Light grey background */
  border: 1px solid #000000;
  /* Light grey border */
  border-radius: 25px;
  /* Rounded edges */
  padding: 10px 15px;
  /* Padding inside the dropdown */
  margin: 20px 0;
  /* Margin around the dropdown */
  width: 200px;
  /* Fixed width */
  cursor: pointer;
  /* Change cursor to pointer */
  font-size: 1rem;
  /* Text size */
  color: #333;
  /* Text color */
  outline: none;
  /* Remove focus outline */
}

.type-selector:hover {
  border-color: #c5ffda;
  /* Darker border on hover */
}

/* Custom dropdown arrow */
.type-selector::-ms-expand {
  display: none;
  /* Hide the default arrow in IE */
}

.type-selector::after {
  content: '▼';
  /* Custom arrow */
  font-size: 0.8rem;
  position: absolute;
  right: 15px;
  top: calc(50% - 0.4em);
  /* Center vertically */
  pointer-events: none;
  /* Clicks pass through */
}

/* Container to position the custom arrow */
.dropdown-container {
  position: relative;
  display: inline-block;
}

/* Ensure the arrow doesn't overlap text */
.type-selector option {
  padding-right: 10px;
}
.custom-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensures clicks on the arrow don't block the select */
  /* Style your arrow icon here */
}


/* HOME PAGE STYLES */
.slideshow-container {
  border: 1px solid rgb(70, 63, 63);
  border-radius:8px;
  box-shadow:2px 2px 2px rgb(121, 114, 114);
  width: 98%;
  max-width: 98%;
  height: 50vh;
        /* Fixed height */
  /* Adjust as needed */
  margin: 10px auto;
  overflow: auto;
      /* Add scrollbars if content overflows */
  
  position: relative;
}
.teaser-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  /* Adjust based on margin to ensure even spacing */
}

.teaser-card {
  max-height: 80vh;
  cursor: pointer;
  width: 100%;
  padding: 20px;
  margin: 20px;
  background-color: #fbfbfb;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  /* Adjustments for width and flex */
  flex: 0 1 calc(100% - 40px);
  /* Subtract double the margin to account for spacing */
  /* max-width: calc(50% - 40px); */
  /* Ensure card does not exceed half the container's width, accounting for margin */
}

/* Optional: Additional styling for when there's only one card */
.teaser-container:only-child {
  flex: 0 1 100%;
  max-width: 100%;
}

.teaser-card:hover {
  transform: translateY(-5px);
    background-color: #f0f0f0;
  /* Move up slightly on hover */
}

/* SALES TARGETS CARDS STYLES */
.sales-target-card-container {
  display: flex;
  justify-content: center;
  gap:20px;
  flex-wrap: wrap;
  margin-top: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sales-target-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px;
  width: 25%;
  min-width: 190px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-bottom: 20px;
}

.sales-target-inner-card {
  margin-top: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.sales-target-non-consignment {
  color: blue;
  margin-bottom: 10px;
}

.sales-target-consignment {
  color: green;
  margin-bottom: 10px;
}

.sales-target-percentage {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.sales-target-progress-bar {
  background: lightgray;
  height: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
}

.sales-target-progress {
  height: 10px;
  border-radius: 5px;
}

.sales-target-delta {
  font-size: 1em;
  color: #666;
}

.sales-target-value {
  margin: 5px;
  font-size: 1.2em;
}

/* CHANGELOG STYLES */
/* Container styling for the main dashboard area */
.changelog-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

/* Header styling */
.changelog-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #222;
}

/* Date input container */
.changelog-date-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.changelog-date-input label {
  margin-right: 10px;
  font-weight: bold;
}

.changelog-date-input-field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Export Button Styling */
.changelog-export {
  text-align: right;
  margin-bottom: 15px;
}

.changelog-export-button {
  padding: 8px 16px;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.changelog-export-button:hover {
  background-color: #218838;
}

/* SKU List Styling */
.changelog-sku-list {
  list-style: none;
  padding: 0;
}

.changelog-sku-item {
  background-color: #f7f7f7;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.changelog-sku-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Pagination Controls */
.changelog-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.changelog-page-size label {
  margin-right: 8px;
  font-weight: bold;
}

.changelog-page-size select {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.changelog-page-controls button {
  padding: 6px 12px;
  border: none;
  background-color: #007BFF;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.2s ease;
}

.changelog-page-controls button:disabled {
  background-color: #ccc;
  cursor: default;
}

.changelog-page-controls span {
  font-size: 1rem;
  color: #333;
}

/* Modal content styling */
.changelog-modal {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  margin: auto;
}

.changelog-modal-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.changelog-createdby {
  font-size: 1rem;
  color: #666;
  margin-bottom: 15px;
}

.changelog-modal-subheader {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555;
}

.changelog-modal-list {
  list-style: none;
  padding: 0;
}

.changelog-modal-list-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.changelog-modal-list-item:last-child {
  border-bottom: none;
}

.field-name {
  font-weight: bold;
  color: #007BFF;
}

.change-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.old-value {
  background-color: #f8d7da;
  color: #721c24;
  padding: 4px 8px;
  border-radius: 4px;
}

.arrow {
  font-weight: bold;
  font-size: 1.2rem;
}

.new-value {
  background-color: #d4edda;
  color: #155724;
  padding: 4px 8px;
  border-radius: 4px;
}

/* Close button styling */
.changelog-close-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #007BFF;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.changelog-close-button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .sales-target-card {
    width: 100%;
  }
}
/* MEDIA QUERIES */



@media screen and (max-width: 1600px) {

  .combined-widgets-container {
    flex-direction: column; /* Stacks the child elements (tables) vertically */
  }

  .widget-container {
    /* Optional: Adjust flex settings for individual widgets if needed */
    flex: 0 0 100%; /* Each table takes full width */
  }
}
/* DOUGHTNUT GRAPH */
.highcharts-credits{
  display: none;
}
.highcharts-label-box {
  display: none;
}

@media screen and (max-width: 982px) {

  .dropdown-content {
      display: none !important;
      /* Hide dropdown content by default */
    }
  
    .dropdown-content.show {
      display: block !important;
      z-index: 199;
      /* Show dropdown when `show` class is added */
    }

  .widget-container{
    max-height: 50vh;
  }

  .nav{
    position: relative; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .hamburger-menu span {
    display: block;
    height: 3px;
    width: 100%;
    background: rgb(255, 255, 255);
    transition: all 0.3s ease;
  }
  
  .hamburger-menu.open span:nth-child(1) {
    transform: rotate(45deg) translate(4.2px, 6px);
  }
  
  .hamburger-menu.open span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-menu.open span:nth-child(3) {
    transform: rotate(-45deg) translate(5.2px, -7px);
  }

  .hamburger-menu {
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
  }

  .nav-links {
    margin-top: 20px;
    flex-wrap: wrap;
        display: none;
    justify-content: center;
    align-items: center;
  }

  .nav-links.active {
    display: flex;
    width: 100%;
    gap: 10px;
  }
  .nav-link.summary{
    margin:0 10px;
  }
  .nav-link {
    flex-basis: calc(25% - 30px); /* Adjust to 50% for two columns */
    text-align: center;
  }

  .logout-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

  }

  .logout-text {
    display: block; /* Show text on mobile */
    color: white; /* Adjust text color as needed */
  }
    .nav-link.receiving{
      margin: 10px;
    }


  td, th {
    min-width: 50px; 
    min-height: 50px; 
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 3px;
    white-space: normal;
  
  }
  th{
    top:23px;
  }
}


@media screen and (max-width: 557px) {
  th{
    top:14px;
  }
    /* Add this to your CSS file */
 /* General styles for larger screens */


 /* Mobile-specific adjustments */
    .non-consignment {
        border-top: 1px solid black;
    
      }
   .chart-container {
     width: 90%;
     /* Make charts wider on mobile */
     margin-bottom: 20px;
     /* Less space between charts */
   }

   /* Adjust the flex container (if your charts are wrapped in a flex container) */
   .sales-targets-title+div {
     flex-direction: column;
     align-items: center;
   }
 }

 /* Additional styles to ensure minimal space between inner divs of the chart container */
 .highcharts-container {
   padding: 0 !important;
   margin: 0 auto;
   /* Center align the Highcharts container within its parent */
 }

 /* Ensure the SVG chart fills its container */
 .highcharts-root {
   width: 100% !important;
   height: auto !important;
   /* Adjust height to maintain aspect ratio or set to a fixed height as needed */
 }